/* CustomCalendar.css */
.custom-calendar {
    font-family: 'Arial', sans-serif;
    width: 500px;
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .nav-button {
    background-color: #ddd;
    margin: 10px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 13px;
  }
  
  .current-month {
    font-size: 28px;

  }
  
  .days-of-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .day-of-week {
    padding: 5px;
    font-size: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  
  .calendar-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 5px;
  }
  
  .calendar-cell:hover {
    background-color: #eee;
    border-radius: 5px;
    
  }
  
  .empty {
    visibility: hidden;
  }
  
  .day-label {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .color-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 0px;
    transition: background-color 0.3s;
  }
  
  .color-circle.green {
    background-color: #66ff66; /* Green for present */
  }
  
  .color-circle.red {
    background-color: #ff6666; /* Red for absent */
  }
  
  .color-circle.blue {
    background-color: #66ccff; /* Blue for holiday */
  }
  
  .color-circle.yellow {
    background-color: #ffff66; /* Yellow for leave */
  }
  .color-circle.orange  {
    background-color: #ffa500; /* Yellow for leave */
  }
  .color-circle.black  {
    background-color: black; /* Yellow for leave */
  }
  .legend {
    display: flex;
    justify-content: center;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .legend-item span {
    margin-left: 5px;
  }
  